<template>
  <section>
    <h2 class="mb-5">Elenco Account</h2>
    <vue-good-table :columns="columns" :rows="account_list">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'action'" class="text-nowrap">
          <b-button
            class="mr-2"
            variant="primary"
            @click="$router.push('/backend/account/' + props.row.id_user)"
            >Dettaglio Account</b-button
          >
          <b-button
            variant="primary"
            @click="
              $router.push(
                '/backend/account/' + props.row.id_user + '/projects'
              )
            "
            >Progetti Account
          </b-button>
        </span>
      </template>
    </vue-good-table>
  </section>
</template>

<script>
import { Requests } from "@/api/requests.js";
import { BButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BButton,
  },

  data: () => ({
    account_list: [],
    columns: [
      {
        label: "ID",
        field: "id_user",
      },
      {
        label: "Tipo Account",
        field: "account_type_label",
      },
      {
        label: "Nome Cliente",
        field: "user_name",
      },
      {
        label: "Cognome Cliente",
        field: "user_surname",
      },
      {
        label: "Azienda Cliente",
        field: "user_companyname",
      },
      {
        label: "Tipo abbonamento",
        field: "plan_type",
      },
      {
        label: "Data Creazione",
        field: "creation_date",
      },
      {
        label: "Azioni",
        field: "action",
      },
    ],
  }),

  created() {
    this.getAccountList();
  },

  methods: {
    async getAccountList() {
      try {
        const response = await Requests.getAccountList();
        this.account_list = response.data;
      } catch {
        console.log(err);
      }
    },
  },
};
</script>
